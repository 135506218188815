<template>
  <div class="container" :style="height">
    <div class="title">我的账户</div>
    <div class="userhead">
      <ul>
        <li>
          <div class="left">
            <h3 style="height:84px;line-height:84px;" v-if="this.withmoney==0">
              <span>账户余额</span>
              <em>${{userMoney}}</em>
            </h3>
            <template v-if="this.withmoney!=0">
              <h3>
                <span>账户余额</span>
                <em>${{userMoney}}</em>
              </h3>
              <p>提现金额${{withmoney}}正在处理中，预计3-5个工作日到账，请注意查收。</p>
            </template>
          </div>
          <div class="right" style="line-height:84px;">
            <a @click="withdrawal">提现</a>
          </div>
        </li>
        <li>
          <div class="left">
            <h3>
              <span>手机号</span>
              {{user.mobile===null?'/':user.mobile}}
            </h3>
            <h3 class="mt20">
              <span>邮箱</span>
              {{user.email===null?'/':user.email}}
            </h3>
          </div>
          <div class="right">
            <div>
              <router-link to="/changephone" v-if="user.mobile==null">去绑定</router-link>
              <router-link to="/changephone" v-else>更换</router-link>
            </div>
            <div class="mt20">
              <router-link to="/changeemail" v-if="user.email==null">去绑定</router-link>
              <router-link to="/changeemail" v-else>更换</router-link>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="title">我的账单</div>
    <div class="usercont pb20">
      <table border="0" cellspacing="0" class="table-recomm">
        <thead>
          <tr>
            <th>内容</th>
            <th>日期</th>
            <th>金额</th>
          </tr>
        </thead>
        <tbody v-if="!isShow">
          <tr v-for="(item,index) in billlist" :key="index" :id="item.id">
            <td>{{item.content}}</td>
            <td>{{item.createTime }}</td>
            <td>${{item.money}}</td>
          </tr>
        </tbody>
      </table>
      <!-- 暂无数据 -->
      <div class="none" v-if="isShow">
        <img src="../assets/images/none.png" alt />
      </div>
    </div>
  </div>
</template>
<script>
// 获取一个用户的用户信息,获取用户的账户金额信息,交易记录,查询用户提现的总金额
import {
  userselectOne,
  moneyselectOne,
  billselectOne,
  cashTotalPrice
} from "@/assets/js/api";
export default {
  name: "usermain",
  data() {
    return {
      height: {
        height: ""
      },
      user: "",
      userMoney: "",
      billlist: [],
      withmoney: 0,
      isShow: false
    };
  },
  methods: {
    // 可视区域高度
    getHight() {
      this.height.height = window.innerHeight - 180 + "px";
    },
    // 获取用户信息
    userlist: function() {
      userselectOne({}).then(result => {
        if (result.code == 0) {
          this.user = result.user;
        }
      });
      moneyselectOne({}).then(result => {
        if (result.code == 0) {
          if (result.appUserMoney) {
            this.userMoney = result.appUserMoney.userMoney;
            localStorage.setItem("userMoney", result.appUserMoney.userMoney);
          } else {
            this.userMoney = 0;
            localStorage.setItem("userMoney", 0);
          }
        }
      });
      billselectOne({}).then(result => {
        if (result.code == 0) {
          this.billlist = result.course;
          if (this.billlist.length == 0) {
            this.isShow = true;
          } else {
            this.isShow = false;
          }
        }
      });
    },
    // 提现
    withdrawal() {
      if (localStorage.getItem("userMoney") == 0) {
        this.$message.error("提现金额不能为0");
      } else {
        this.$router.push("/withdrawal");
      }
    },
    // 查询用户提现的金额
    cashTotalPrice() {
      cashTotalPrice({}).then(result => {
        if (result.code == 0) {
          this.withmoney = result.cashMoney;
        }
      });
    }
  },
  mounted() {
    this.userlist();
    this.cashTotalPrice(); //查询用户提现的金额
  },
  created() {
    this.getHight();
    window.addEventListener("resize", this.getHight);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHight);
  }
};
</script>
